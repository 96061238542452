import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Layout, Header } from '/src/components'
import { Contact } from '../components/contact'

const Product = () => {
  return (
    <Layout>
      <Header />
      <section>
        <section className='hero'>
          <div className='wrapper'>
            <div className='hero__backdrop'>
              {/* <div className='hero__see-more'>&laquo;- Zobacz więcej poniżej</div> */}
            </div>
            <div className='hero__background' />
            {/* <a href='#offer' className='hero__arrow'>
            <Arrow />
          </a> */}
            <div className='hero__claim'>
              <div className='hero__claim-bg' />
              <h1 className='hero__claim-context'>
                Nowoczesne domy systemowe<span>&nbsp;na zgłoszenie</span>
              </h1>
            </div>
            <a name='offer' />
          </div>
        </section>
        <div className='wrapper'>
          <section className='content-section'>
            <h2>Domy systemowe</h2>
            <p>
              Nasze domy systemowe, to rozwiązanie łączące efektywność kosztową,
              szybkość budowy i nowoczesne rozwiązania. Oferujemy domy na
              zgłoszenie do 35m<sup>2</sup>. Jeżeli masz działkę, możemy
              stworzyć dla Ciebie indywidualne rozmieszczenie zgodne z planem
              zagospodarowania przestrzeni.
            </p>
          </section>
          <section className='content-section'>
            <div className='features-list'>
              <div className='feature'>
                <h3 className='feature__title'>
                  Nowoczesny, wygodny, bezpieczny
                </h3>
                <div className='feature__image'>
                  <StaticImage src='../images/domy/1.png' />
                </div>
                <div className='feature__description'>
                  Dzięki wykorzystaniu nowoczesnych materiałów nasze domy są
                  ciepłe i efektywne energetycznie. Dobra izolacja akustyczna
                  jest dodatkowym atutem. Przeszklenia i przestronny projekt
                  wnętrza zapewniają poczucie przestrzeni.
                </div>
              </div>
              <div className='feature'>
                <h3 className='feature__title'>
                  Twój dom, dopasowany do Ciebie
                </h3>
                <div className='feature__image'>
                  <StaticImage src='../images/domy/2.png' />
                </div>
                <div className='feature__description'>
                  Oferujemy różne warianty układów wewnętrznych. Dzięki temu
                  każdy znajdzie rozwiązanie odpowiednio dopasowane do własnych
                  potrzeb. Budowę kończymy na etapie stanu developerskiego -
                  dzięki temu możesz wykończyć swoje wnętrza zgodnie z własnymi
                  preferencjami.
                </div>
              </div>
              <div className='feature'>
                <h3 className='feature__title'>Dostępny bez formalności</h3>
                <div className='feature__image'>
                  <StaticImage src='../images/domy/3.png' />
                </div>
                <div className='feature__description'>
                  Do budowy naszego domu Oferujemy pomoc w dopełnieniu
                  koniecznych formalności, a także w umieszczeniu Twojego nowego
                  domu na działce.
                </div>
              </div>
            </div>
          </section>
          <section className='content-section'>
            <h2>Kup i wynajmij</h2>
            <p>
              Nasze domy systemowe świetnie sprawdzą się również jako
              nieruchomości na wynajem. Jeżeli posiadasz działkę inwestycyjną
              pomożemy zaprojektować optymalne rozmieszczenie domów pod
              wynajem:&nbsp;
              <a class='blue' href='mailto:system@stalproject.eu'>
                system@stalproject.eu
              </a>
            </p>
          </section>
          <section className='content-section'>
            <h2>Zamów już dziś</h2>
            <p>
              Zapraszamy do kontaktu pod adresem mailowym:&nbsp;
              <a class='blue' href='mailto:system@stalproject.eu'>
                system@stalproject.eu
              </a>
              .
              <br />
              Pomożemy dobrać odpowiedni układ i rozmieścić Twój nowy dom na
              działce.
            </p>
          </section>
        </div>
      </section>
      <Contact />
    </Layout>
  )
}

export default Product
